import * as React from "react";
import { Helmet } from "react-helmet";
import LayoutService from "../../templates/LayoutService";
import {
	blogHeader,
	blogPostContainer,
	postContentBlock,
} from "../../styles/components/blog.module.scss";
import { pageContentMedia } from "../../styles/components/blocks.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { imgBox, videoBox } from "../../styles/components/boxes.module.scss";
import "../../styles/components/content.module.scss";
import ServicesList from "../../components/blocks/ServicesList";

const BlogPage = () => {
	return (
		<LayoutService>
			<Helmet>
				<title>
					What Size Party Boat Do I Need for a Group of Six? | Tampa Bay Boating
					Adventures
				</title>
				<meta
					name="description"
					content="20 feet? 30 feet? What's the perfect size for my group? Determining the PERFECT size boat for your charter can be difficult without years of experience. Our guide will help you determine the ideal size for your charter group!"
				/>
				<meta name="keywords" content="Tips" />
			</Helmet>
			<main className="container">
				<div className="row">
					<div className="col-xxs-12">
						<div className={blogHeader}>
							<div className={imgBox}>
								<StaticImage
									alt="power boats racing near mangroves tampa"
									src="../images/blog/power-boats-racing-near-mangroves-tampa.jpg"
								/>
							</div>
						</div>
						<div className={blogPostContainer}>
							<div className="row center">
								<div className="col">
									<h1>
										What Size Boat Should I Charter or Rent for a Group of Six?
									</h1>
									<p>November 02, 2023</p>
								</div>
							</div>
							<div className="row">
								<div className="col-xxs-12">
									<div className={postContentBlock}>
										<h3>
											Booking the correct sized party boat in Tampa Bay is the
											difference between a cramped cruise and extra room for
											dancing.
										</h3>
										<p>
											When planning a boat party, it is vitally important to
											think about the size of the boat that you need for your
											group. The size of a boat you will require is dependent on
											a number of different factors including what you plan to
											use the boat for. So to help you out, we’ve created a
											little explainer to help with your boat party plans.
										</p>
										<div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="various size boats at party island"
													src="../../images/banners/various-size-boats-at-party-island.jpg"
												/>
											</div>
										</div>
										
										<h2>
											The Ideal Vessel Size is Determined by Your Use Case
										</h2>
										<h3>
											Fishing vessels require more room, while lounging on a
											party boat requires less room.
										</h3>
										<p>
											The size of the boat that you need for six people is not
											always the same. It depends on what you need the boat for,
											and how long you intend to spend on it. If you are
											planning on going fishing, for example, you would need a
											larger boat with more storage space for fishing equipment.
											Boats used for water sports may need different features
											and space to tow equipment and jet skis.
										</p>
										<p>
											Party boats need less storage space, but require space for
											coolers, an area to relax and lie in the sun, and space to
											move around and socialize with your friends. Boat charter
											companies will be able to advise on what size of boat is
											best for your party and your needs – and this is where we
											come in.
										</p>
										<p>
											Our party boat is perfectly designed for this. At 26’,
											Adventure I is designed for up to 112 Guests, however we
											have found that 12-14 is comfortable.{" "}
											<strong>
												For a group of 6, a 26’ vessel will allow enough room
												for lounging, drinking, dancing, and stretching your
												legs. We would not recommend going smaller than 20’ for
												a 6-person charter.
											</strong>{" "}
											The spaciousness of our boat is one reason that we have
											great customer reviews – with customers returning again
											and again for our party boat services.
										</p>
										<hr />
										<div className={pageContentMedia}>
											<div className={imgBox}>
												<StaticImage
													alt="boat cruise with captain and guest next to deck boat"
													src="../../images/banners/boat-cruise-with-captain-and-guest-next-to-deck-boat.jpg"
												/>
											</div>
										</div>
										<h2>
											Larger Charter Boats Offer More Versatility in Lounging
											Options
										</h2>
										<h3>
											When you’re cruising through Channelside, Downtown, or
											Tampa Bay, you’ll want options for how you choose to
											relax.
										</h3>
										<p>
											Party boats are all about spending time with your friends
											and therefore, it is important to take this into
											consideration when choosing your party boat size. Party
											boats should have enough room to move around – and our
											boat sure has this.{" "}
											<strong>
												If you feel like socializing, or would rather lounge and
												take in the views of Davis Island and the mega-mansions
												located there, you’ll want to have a variety of
												relaxation options at your disposal!
											</strong>{" "}
											You will easily be able to move to the front and back of
											the boat as it moves through the water – feeling the
											breeze in your hair with a drink in your hand. What’s not
											to love?! If you are looking for a more relaxed vibe, we
											have plenty of chilled seating options where you can
											listen to tunes from our excellent sound system and bathe
											in the hot Florida sun. On the back of the boat, we have a
											great tanning platform ready for you to top up that tan.
										</p>
										<hr />
										<h2>
											Booking a Six-Person Charter is Easy with Tampa Bay
											Boating Adventures
										</h2>
										<h3>
											Our 26’ party boat is spacious enough for your six-person
											party boat charter.
										</h3>
										<p>
											Whether you are looking for a more casual relaxed party
											boat or one for a special occasion, we can help. Here at
											Tampa Bay Boating Adventures, our boats are perfect for
											accommodating six people. In fact, they are rated for up
											to 16 people. This means that a party of six will have
											extra space to stretch out, relax and enjoy the party. Our
											business is purely for party cruises rather than fishing
											or water sports, meaning that they are in excellent
											condition for relaxation and fun.{" "}
											<strong>
												We offer a one-of-a-kind experience focused on
												entertainment and comfort, so you can enjoy a fun-filled
												day with your friends.
											</strong>{" "}
											Book a charter boat party with us today and savor the
											spaciousness that’s offered on our 26’ deck boat.
										</p>
										<hr />
										<h2>Select Your Party Boat Charter</h2>
										<h3>
											See what all's included with our wide variety of party
											boat charter packages.
										</h3>
									</div>
								</div>
							</div>
						</div>
						<ServicesList />
					</div>
				</div>
			</main>
		</LayoutService>
	);
};

export default BlogPage;
